import { categoryIconConfig, ApolloError } from "@lumar/shared";
import { ReportCategory, useGetReportCategoriesQuery } from "../../graphql";
import { ReportCategoryElement, ReportOption } from "../utils/constants";
import { useMemo } from "react";

export function useReportCategoriesTree(
  reportTemplates?: ReportOption[],
  healthscoreOnly?: boolean,
): [
  { loading: boolean; error: ApolloError | undefined },
  {
    getCategoryList: (maxLevel?: number) => ReportCategoryElement[];
    getCategoryListByCode: (
      code: string,
      maxLevel?: number,
    ) => ReportCategoryElement[];
  },
  ReportCategoryElement[],
] {
  const { loading, data, error } = useGetReportCategoriesQuery();

  const categories = useMemo(() => {
    function testChilds(code: string): boolean {
      if (!reportTemplates?.length) return true;
      return Boolean(
        reportTemplates?.find((e) =>
          e.categories?.find((c) => c.code === code || c.parentCode === code),
        ),
      );
    }

    function createCrawlReportCategoriesTree(
      allReportCategoriesData: ReportCategory[],
      parent: ReportCategoryElement | null | undefined,
    ): ReportCategoryElement[] {
      /* eslint-disable fp/no-mutating-methods*/
      const parentNodes = allReportCategoriesData
        .filter(
          (e) =>
            (e.parentCode ?? null) === (parent?.code ?? null) &&
            testChilds(e.code) &&
            ((healthscoreOnly && e.healthScore) || !healthscoreOnly),
        )
        .sort((a, b) => a.position - b.position);

      return parentNodes.map((e) => {
        const element = {
          code: e.code,
          name: e.name,
          parent,
          description: e.description,
          icon: categoryIconConfig.get(e.code),
          position: e.position,
          level: (parent?.level ?? -1) + 1,
          subCategories: [],
        };
        return {
          ...element,
          subCategories:
            createCrawlReportCategoriesTree(allReportCategoriesData, element) ??
            [],
        };
      });
    }

    return createCrawlReportCategoriesTree(
      data?.getReportCategories?.nodes ?? [],
      null,
    );
  }, [data?.getReportCategories?.nodes, healthscoreOnly, reportTemplates]);

  function getChildrenListCascade(
    parent: ReportCategoryElement,
    maxLevel?: number,
  ): ReportCategoryElement[] {
    return parent.subCategories.reduce((acc, e) => {
      if (e.level > (maxLevel ?? Number.MAX_SAFE_INTEGER)) return acc;
      return [...acc, e, ...getChildrenListCascade(e, maxLevel)];
    }, [] as ReportCategoryElement[]);
  }

  function getCategoryList(maxLevel?: number): ReportCategoryElement[] {
    return categories.reduce((acc, e) => {
      if (e.level > (maxLevel ?? Number.MAX_SAFE_INTEGER)) return acc;
      return [...acc, e, ...getChildrenListCascade(e, maxLevel)];
    }, [] as ReportCategoryElement[]);
  }

  function getCategoryListByCode(
    code: string,
    maxLevel?: number,
  ): ReportCategoryElement[] {
    return categories.reduce((acc, e) => {
      if (e.level > (maxLevel ?? Number.MAX_SAFE_INTEGER)) return acc;
      if (e.code !== code) return acc;
      return [...acc, e, ...getChildrenListCascade(e, maxLevel)];
    }, [] as ReportCategoryElement[]);
  }

  return [
    { loading: loading, error: error },
    {
      getCategoryList,
      getCategoryListByCode,
    },
    categories,
  ];
}
